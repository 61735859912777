import React, { useEffect, useState } from "react"
import Modal from "../modal"
import * as localStorageService from "../../services/localstorage.service"
import * as stellarService from "../../services/stellar.service"
import * as cryptoService from "../../services/crypto.service"
import { FormattedMessage } from "react-intl"

const ActivateAccountModal = ({ isOpen, handleClose, handleSuccess }) => {
  const [xlmFee, setXlmFee] = useState("")
  const [xlmBalance, setXlmBalance] = useState(0)
  const [password, setPassword] = useState("")

  useEffect(() => {
    const publicKey = localStorageService.getPublicKey()
    stellarService.getFee().then(fee => setXlmFee(fee))
    stellarService
      .getStellarAccount(publicKey)
      .catch(e => e)
      .then(account =>
        stellarService
          .getBalance(account, "native")
          .catch(e => 0)
          .then(balance => setXlmBalance(balance))
      )
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    const secret = cryptoService.decryptSecret(
      localStorageService.getEncryptedSecret(),
      password
    )
    const keyPair = stellarService.getKeyPair(secret)
    stellarService.enableDEVPTrust(keyPair)
    handleSuccess()
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <h2>
        <FormattedMessage id="activateAccountModal.title" />
      </h2>
      <p>
        <strong>
          <FormattedMessage id="activateAccountModal.operationLabel" />
        </strong>{" "}
        <FormattedMessage id="activateAccountModal.operation" />
      </p>
      <p>
        <strong>
          <FormattedMessage id="activateAccountModal.balance" />:
        </strong>{" "}
        {xlmBalance} XLM
      </p>
      <p>
        <strong>
          <FormattedMessage id="activateAccountModal.maxFee" />
        </strong>{" "}
        {xlmFee / 10000000} XLM
      </p>
      <form className="form-container" onSubmit={e => handleSubmit(e)}>
        <input
          type="password"
          name="password"
          id="password"
          onChange={e => setPassword(e.target.value)}
        />
        <button className="button -primary" onClick={e => handleSubmit(e)}>
          <FormattedMessage id="activateAccountModal.signButton" />
        </button>
      </form>
    </Modal>
  )
}

export default ActivateAccountModal
