import React from "react"

const Card = ({ children }) => {
  return (
    <div className="card grid bg-white dark:bg-cardBackground-dark rounded-lg border border-cardBorder dark:border-cardBorder-dark shadow overflow-hidden leading-6">
      <div className="p-6 min-w-200px md:flex-row">{children}</div>
    </div>
  )
}

Card.Row = ({ children }) => (
  <div className="flex flex-col items-center justify-between md:flex-row md:justify-around">
    {children}
  </div>
)

Card.Column = ({ children, ...props }) => (
  <div {...props} className="max-w-full md:max-w-1/3 my-3">
    {children}
  </div>
)

export default Card
